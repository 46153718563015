import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/Seo"
import NotFound from "../components/NotFound"
import BottomBlock from "../components/BottomBlock"
import { withPrismicUnpublishedPreview } from "gatsby-plugin-prismic-previews"

import "./index.scss"

function NotFoundPage() {
  return (
    <Layout className="page homePage">
      <SEO title="404: Not found | LicenseSpring" />
      <NotFound />
      <BottomBlock />
    </Layout>
  )
}
export default withPrismicUnpublishedPreview(NotFoundPage)
