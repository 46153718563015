import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import Button from '../Button'

import './notfound.scss'

export default () =>
  <StaticQuery
    query={graphql`
        query {
            cloudBased: file(relativePath: { eq: "notfound.png" }) {
                childImageSharp {
                    # Specify the image processing specifications right in the query.
                    # Makes it trivial to update as your page's design changes.
                    fluid(maxWidth: 3000) {
                    ...GatsbyImageSharpFluid
                    }
                }
            }
        }
      `}
    render={data => {
      return (
        <section className="container NotFound">
          <div className="NotFound__meta">
            <h2>Page not found</h2>
            <div className="NotFound__description">
              We can't seem to find the page you're looking for.
          </div>
          <Button
            color="blue"
            label="Back to homepage"
            to="/"
            className="NotFound__cta"
          />
          </div>
          <div className="NotFound__image"><Img className="NotFound__heroImage" fluid={data.cloudBased.childImageSharp.fluid} alt="Not found" /></div>
          <Button
            color="blue"
            label="Back to homepage"
            to="/"
            className="NotFound__mobile_cta"
          />
        </section>
      )
    }}
  />
